var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'monitor-view-wrapper': _vm.isMonitorView},attrs:{"id":"monitor-view"}},[_c('div',{staticClass:"mt-4 px-5"},[_c('h2',[_vm._v("Heute")]),_c('div',{class:{'monitor-view': _vm.isMonitorView, 'normal-view': !_vm.isMonitorView}},[(_vm.show_SL)?_c('v-data-table',{ref:"TodaySL",staticClass:"elevation-1 my-2",class:{'large-table': _vm.isMonitorView},attrs:{"headers":_vm.comp_Table_Header_SL,"items":_vm.comp_TodaySL,"no-data-text":"Kein Schiessleiter eingebucht","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"warning"}},[_c('h3',[_vm._v("Schiessleiter")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getTodaySL}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.beginn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})))])]}},{key:"item.beenden",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error my-1",attrs:{"text":""},on:{"click":function($event){return _vm.ende_other_aufsicht(item)}}},[_c('v-icon',[_vm._v(" mdi-clock-end ")])],1)]}}],null,false,3697059186)}):_vm._e(),(_vm.show_SA)?_c('v-data-table',{ref:"TodaySA",staticClass:"elevation-1 my-2",class:{'large-table': _vm.isMonitorView},attrs:{"headers":_vm.comp_Table_Header_SA,"items":_vm.comp_TodaySA,"no-data-text":"Kein Standaufsicht eingebucht, eingeschränkter Schiessbetrieb!","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"info"}},[_c('h3',[_vm._v("Standaufsicht")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getTodaySA}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.beginn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})))])]}},{key:"item.beenden",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error my-1",attrs:{"text":""},on:{"click":function($event){return _vm.ende_other_aufsicht(item)}}},[_c('v-icon',[_vm._v(" mdi-clock-end ")])],1)]}}],null,false,706949975)}):_vm._e()],1),(_vm.show_user)?_c('v-data-table',{ref:"TodayMember",staticClass:"elevation-1 my-2",attrs:{"headers":_vm.headers,"items":_vm.comp_TodayMember,"sort-by":"KurzBez","group-by":"KurzBez","no-data-text":"Kein Schütze eingebucht"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"success"}},[_c('h3',[_vm._v("Schützen")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getTodayMember}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.beginn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})))])]}}],null,false,2520225238)}):_vm._e(),(_vm.isMonitorView)?_c('div',{staticClass:"clock-container"},[_c('div',{staticClass:"clock-overlay"},[_vm._v(" "+_vm._s(_vm.currentTime)+" ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }